import type { ReactNode, MouseEvent } from 'react';
import {
   Box,
   BoxProps,
   Flex,
   forwardRef,
   HStack,
   MenuButton,
   MenuButtonProps,
   MenuItem,
   MenuItemProps,
   Text,
} from '@chakra-ui/react';
import { useTrackedOnClick } from '@ifixit/tracking-hooks';

type StoreMenuButtonProps = MenuButtonProps & {
   icon?: ReactNode;
};

export const StoreMenuButton = forwardRef<StoreMenuButtonProps, 'button'>(
   ({ children, icon, ...otherProps }, ref) => (
      <MenuButton
         ref={ref}
         fontSize="sm"
         color="gray.300"
         _hover={{ color: 'white' }}
         {...otherProps}
      >
         <HStack alignItems="center">
            <Text
               color="inherit"
               fontSize="sm"
               lineHeight="1em"
               display={{ base: 'none', sm: 'block' }}
            >
               {children}
            </Text>
            <StoreFlagBackdrop>{icon}</StoreFlagBackdrop>
         </HStack>
      </MenuButton>
   )
);

const StoreFlagBackdrop = (props: BoxProps) => (
   <Box p="1.5" borderRadius="base" bg="gray.800" {...props} />
);

type StoreMenuItemProps = Omit<MenuItemProps, 'children'> & {
   icon: ReactNode;
   name: string;
   currency: string;
   onClick?: (event: MouseEvent) => void | Promise<void>;
};

export const StoreMenuItem = forwardRef<StoreMenuItemProps, 'div'>(
   ({ icon, name, currency, onClick, ...otherProps }, ref) => {
      const trackAnalyticsOnClick = useTrackedOnClick({
         clickName: name,
         isStoreLink: true,
         onClick,
         ...otherProps,
      });
      return (
         <MenuItem
            ref={ref}
            fontSize="sm"
            color="black"
            onClick={trackAnalyticsOnClick}
            {...otherProps}
         >
            <Flex w="full" align="center">
               {icon}
               <Text ref={ref} ml="3" mt="-1px" flexGrow={1}>
                  {name}
               </Text>
               <Text ref={ref} ml="2" mt="-1px" color="gray.500">
                  {currency}
               </Text>
            </Flex>
         </MenuItem>
      );
   }
);
