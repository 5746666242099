import { useRef, useEffect } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { _js } from '@ifixit/localize';
import { color, space, fontSize, fontWeight, breakpoint, borderRadius } from '@ifixit/primitives';
import { FaIcon } from '@ifixit/icons';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass';
import { KeyboardNavigation } from 'Shared/keyboard_navigation';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

interface SearchBarProps {
   isOnCart?: boolean;
   isMobile?: boolean;
   tabbable?: boolean;
}

const SearchHotkey = styled.div`
  position: relative;
  right: ${space[4]};
  pointer-events: none;
  color: ${color.gray[200]};
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.bold};
  background-color: #d1d1d1;
  border-radius: ${borderRadius.md};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.18s;
`;

const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  flex-grow: 1;
  min-width: 100px;
  margin: 0;
  border-radius: ${borderRadius.pill};
  background-color: ${color.gray[200]};
  color: ${color.gray[500]};
  overflow: hidden;
  outline: 2px solid transparent;
  transition: all 0.18s;

  @media (min-width: ${breakpoint.xl}) {
    margin: 0 ${space[4]};
  }

  &:focus-within {
    background-color: ${color.gray[100]};
    outline-color: ${color.blue[500]};

    ${SearchHotkey} {
      background-color: ${color.gray[100]};
      color: transparent;
    }
  }
`;

const TextBox = styled.input.attrs({ type: 'text' })`
  height: 100%;
  appearance: none;
  box-sizing: border-box;
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  color: ${color.gray[700]};
  font-size: ${fontSize.md};
  padding: ${space[1]} ${space[6]} ${space[1]} ${space[7]};

  &::placeholder {
    color: ${color.gray[500]};
  }
`;

const IconContainer = styled.button.attrs({ type: 'submit', 'aria-label': 'submit' })`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: ${space[4]};
  padding-inline: 0;
  pointer-events: none;
`;

const onSubmit = () => {
   trackInPiwikAndGA({
      eventCategory: 'Search',
      eventAction: 'Search - Header',
   });
};

const SearchBar: FC<SearchBarProps> = ({ isOnCart = false, isMobile = false, tabbable = true }) => {
   const placeholder = isOnCart ? _js('Search by device or model #') : _js('Search');
   const textBoxRef = useRef<HTMLInputElement>(null);

   useEffect(() => {
      const setFocus = () => textBoxRef.current?.focus();
      new KeyboardNavigation({ keys: { '/': setFocus } });
   }, []);

   return (
      <Form
         action={window.shared_constants.GuideURI('TAG_SEARCH')}
         method="get"
         onSubmit={onSubmit}
      >
         <IconContainer>
            <FaIcon
               icon={faMagnifyingGlass}
               focusable="false"
               color={color.gray[600]}
               boxSize="16px"
            />
         </IconContainer>
         <TextBox
            ref={textBoxRef}
            name="query"
            aria-label="search"
            id={isMobile ? 'mobileSearchForm' : 'searchForm'}
            spellCheck="false"
            autoComplete="off"
            size={1}
            placeholder={placeholder}
            tabIndex={tabbable ? 0 : -1}
         />
         {!isMobile && <SearchHotkey>/</SearchHotkey>}
         {isOnCart && (
            <>
               <input type="hidden" name="doctype" value="product" />
               <input type="hidden" name="search-store" value="1" />
            </>
         )}
      </Form>
   );
};

export default SearchBar;
