import USFlag from 'Shared/icons/flags/us.svg';
import AUFlag from 'Shared/icons/flags/au.svg';
import CAFlag from 'Shared/icons/flags/ca.svg';
import DEFlag from 'Shared/icons/flags/de.svg';
import FRFlag from 'Shared/icons/flags/fr.svg';
import UKFlag from 'Shared/icons/flags/uk.svg';
import EUFlag from 'Shared/icons/flags/eu.svg';
import { CSSProperties } from 'react';
import { StoreType } from '..';

const StoreFlag = ({
   storeCode,
   style,
   // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
   id = null,
}: {
   storeCode: StoreType['storeCode'];
   style?: CSSProperties;
   id?: string;
}) => {
   const svgProps = {
      style,
      id,
      'data-country': storeCode,
   };

   switch (storeCode) {
      case 'au': {
         return <AUFlag {...svgProps} />;
      }
      case 'ca': {
         return <CAFlag {...svgProps} />;
      }
      case 'de': {
         return <DEFlag {...svgProps} />;
      }
      case 'dev':
      case 'fr': {
         return <FRFlag {...svgProps} />;
      }
      case 'uk': {
         return <UKFlag {...svgProps} />;
      }
      case 'eu_test':
      case 'eu': {
         return <EUFlag {...svgProps} />;
      }
      case 'test':
      case 'us':
      default: {
         return <USFlag {...svgProps} />;
      }
   }
};

export default StoreFlag;
