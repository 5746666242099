import { useContext } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { color } from '@ifixit/primitives';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass';
import { AppProvider } from '@ifixit/app';
import { FaIcon } from '@ifixit/icons';
import { ShopifyStorefrontProvider } from '@ifixit/shopify-storefront-client';
import { BreakpointsContext } from 'Shared/utils/breakpoints';
import * as HeaderActions from 'Shared/Header/actions';
import UserDropdown from './user_dropdown';
import AuthIcons from './auth_icons';
import WorkbenchDropdown from './workbench_dropdown';
import LanguageDropdownContainer from './language_dropdown_container';
import MiniCartDropdown from './mini_cart_dropdown';
import NotificationsDropdown from './notifications_dropdown';
import withNotifications from './with_notifications';

const SearchButton = styled.button`
   color: ${color.white}
   display: flex;

   svg {
      transition: color 0.1s;
   }

   &:hover svg {
      color: ${color.gray[200]};
   }
`;

type TeamType = {
   teamLink: string;
   teamCount: number;
};

export type UserInfoType = {
   username: string;
   uniqueUsername: string;
   profileUrl: string;
   ordersUrl: string;
   notificationsUrl: string;
   avatar: string;
   team: TeamType;
   canIViewManageTab: boolean;
};

const UserLogin = ({ userInfo }: { userInfo: UserInfoType }) =>
   userInfo ? (
      <ConnectedUserDropdown
         username={userInfo.username}
         uniqueUsername={userInfo.uniqueUsername}
         profileUrl={userInfo.profileUrl}
         ordersUrl={userInfo.ordersUrl}
         notificationsUrl={userInfo.notificationsUrl}
         avatar={userInfo.avatar}
         team={userInfo.team}
         canIViewManageTab={userInfo.canIViewManageTab}
      />
   ) : (
      <AuthIcons />
   );

const ConnectedNotificationsDropdown = withNotifications(NotificationsDropdown);
const ConnectedUserDropdown = withNotifications(UserDropdown);

const mapDispatchToProps = (dispatch: any) => ({
   toggleMobileSearch: (value: any) => dispatch(HeaderActions.toggleMobileSearch(value)),
});

type DropdownsType = {
   userInfo: any;
   languageDropdown: any;
   miniCart: any;
   shopifyStore: {
      currencyCode: string;
      storeCode: string;
      shopDomain: string;
      storefrontAccessToken: string;
   };
};

const NavIcons = ({
   dropdowns,
   isOnCart,
   toggleMobileSearch,
}: {
   dropdowns: DropdownsType;
   isOnCart: any;
   toggleMobileSearch?: (val: boolean) => void;
}) => {
   const breakpoints = useContext(BreakpointsContext);

   const showMobileSearch = () => {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      toggleMobileSearch(true);
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      document.getElementById('mobileSearchForm').focus();
   };

   const { languageDropdown, userInfo, shopifyStore } = dropdowns;
   const { languages, translationPreferencesUrl } = languageDropdown;

   if (breakpoints.isMobileTablet()) {
      return (
         <AppProvider ifixitOrigin={window.origin} priceTier={null}>
            <ShopifyStorefrontProvider {...shopifyStore}>
               {breakpoints.isMobile() && (
                  <SearchButton aria-label="Search" role="button" onClick={showMobileSearch}>
                     <FaIcon
                        icon={faMagnifyingGlass}
                        focusable="false"
                        color={color.white}
                        boxSize={{ base: '20px', sm: '24px' }}
                     />
                  </SearchButton>
               )}
               <MiniCartDropdown />
               <UserLogin userInfo={userInfo} />
            </ShopifyStorefrontProvider>
         </AppProvider>
      );
   }

   return (
      <AppProvider ifixitOrigin={window.origin} priceTier={null}>
         <ShopifyStorefrontProvider {...shopifyStore}>
            <WorkbenchDropdown />
            {userInfo && (
               <ConnectedNotificationsDropdown notificationsUrl={userInfo.notificationsUrl} />
            )}
            <MiniCartDropdown />
            {!isOnCart && (
               <LanguageDropdownContainer
                  languages={languages}
                  translationPreferencesUrl={translationPreferencesUrl}
               />
            )}
            <UserLogin userInfo={userInfo} />
         </ShopifyStorefrontProvider>
      </AppProvider>
   );
};

export default connect(null, mapDispatchToProps)(NavIcons);
